function TermsAndConditions() {

    return (
        <iframe
            src="https://privacy.cortina-consult.com/privacy?id=qe1Me5lkR7fTPqwsc0Wq&lang=en&script=false"
            style={{
                border: 'none',
                width: "100%",
                minHeight: "90vh",
            }}
            title={"Terms & Conditions"}
        >
        </iframe>
    )
}

export default TermsAndConditions;