import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
import { transformToSelectablesUtils } from '../../utils/utils';

const PositionTree = ({ setSelection, externalSetKey, assetList, menuItemKey, setNewPositionArray, type, reload }) => {
    const [gData, setGData] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectables, setSelectables] = useState({});
    

    useEffect(() => {
        if (assetList && assetList.length > 0) {
            const transformedData = transformData(assetList);
            setGData(transformedData);
            
        }
    }, [assetList, reload]);

    useEffect(() => {
        if (assetList && assetList.length > 0) {
            const transformedData = transformData(assetList);
            setGData(transformedData);
            
        }
    }, [selectedKeys, selectables, assetList]);

    const transformData = (inputData) => {
        if (type === "asset") {
            return inputData.map(asset => ({
                title: asset.name,
                key: asset.internal_identifier,
                asset_id: asset.id 
            }))
        } else {
            inputData.sort((a, b) => a.position - b.position)
            return inputData.map(ws => ({
                title: ws.name,
                key: ws.internal_identifier,
                workstation_id: ws.id 
            }))
        }
    };

   

    const onDrop = (info) => {
        
        
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        let dragId = ""
        const data = [...gData];
        const findItemByKey = (key) => {
            return data.find(item => item.key === key);
        };
    
        
        const removeDraggedItem = () => {
            const draggedIndex = data.findIndex(item => item.key === dragKey);
            switch (type) {
                case "asset":
                    if (draggedIndex !== -1) {
                        dragId = data[draggedIndex].asset_id
                        data.splice(draggedIndex, 1);
                        return true;
                    }
                    break;
                case "workstation":
                    if (draggedIndex !== -1) {
                        dragId = data[draggedIndex].workstation_id
                        data.splice(draggedIndex, 1);
                        return true;
                    }
                    break;
                default:
                    break;
                }
            return false;
        };
    
        
        removeDraggedItem();
            const dropItem = findItemByKey(dropKey);
            const dragItem = findItemByKey(dragKey);
            const dropIndex = data.indexOf(dropItem);
            switch (type) {
                case "asset": {
                    if (info.dropPosition !== -1) {
                        data.splice(dropIndex + 1, 0, { title: info.dragNode.title, key: dragKey, asset_id: dragId });
                    } else {
                        data.splice(dropIndex, 0, { title: info.dragNode.title, key: dragKey, asset_id: dragId });
                    }}
                    break;
                case "workstation": {
                    if (info.dropPosition !== -1) {
                        data.splice(dropIndex + 1, 0, { title: info.dragNode.title, key: dragKey, workstation_id: dragId });
                    } else {
                        data.splice(dropIndex, 0, { title: info.dragNode.title, key: dragKey, workstation_id: dragId });
                    }}
                    break;
                default:
                    break;
                }
        setGData(data)
    };

    useEffect(() => {
        console.log("gData", gData)
        const posArray = createPositionArray(gData)
        setNewPositionArray(posArray)
    }, [gData])

    function createPositionArray(data) {
        console.log("data", data);
        let positionArray = []
        if (type === "asset") {
            positionArray = data.map((item, index) => ({
                asset_id: item.asset_id,
                position: index + 1
            }))
        } else {
            positionArray = data.map((item, index) => ({
                workstation_id: item.workstation_id,
                position: index + 1
            }))
        }
        console.log("positionArray", positionArray)
        return positionArray
    }

    const onSelect = (selectedKeys, info) => {
        setSelectedKeys(selectedKeys);
        const selectedAsset = selectables[selectedKeys[0]];
        if (selectedAsset) {
            setSelection(
                selectedAsset.key,
                selectedAsset.name,
                selectedAsset.type,
                selectedAsset.internal_identifier,
                selectedAsset.id,
                selectedAsset.position,
                selectedAsset.type,
                selectedAsset.manufacturer,
                selectedAsset.equipment_number
            );
        }
    };

    

    return (
        <Tree
            expandedKeys={expandedKeys}
            draggable
            onDrop={onDrop}
            treeData={gData}
            selectedKeys={selectedKeys}
        />
    );
};

export default PositionTree;