
export const hostName = process.env.REACT_APP_HOST_NAME


export async function fetchApi (auth, path) {
    let token = ""
    if (auth.user !== undefined) {
        token = auth.user.access_token
    } else {
        return ""
    }

    try {
        let response = await fetch(hostName + path, {
            headers: {
                "Accept": "application/json",
                "Authorization": 'Bearer ' + token,
            },
            method: "GET"
        })
        if (response.status === 401) {
            auth.removeUser()
            auth.signoutRedirect({
                id_token_hint: auth.user.id_token,
                post_logout_redirect_uri: window.location.href
            })
            auth.signinRedirect();
        } else if (response.status === 403) {
            window.location.replace("/");
        }
        return await response.json();
    } catch (error) {
        throw new Error("Error requesting API:", error);
    }
}

export async function deleteTimeBookingEntry(auth, path) {
    let token = ""
    if (auth.user !== undefined) {
        token = auth.user.access_token
    } else {
        return ""
    }

    try {
        let response = await fetch(hostName + path, {
            headers: {
                "Accept": "application/json",
                "Authorization": 'Bearer ' + token,
            },
            method: "DELETE"
        });
        return await response;
    } catch (error) {
        throw new Error("Error requesting API:", error);
    }
}

export async function sendNewMessage(auth, path, attributesArray) {
    let token = ""
    if (auth.user !== undefined) {
        token = auth.user.access_token
    } else {
        return ""
    }

    //Create JSON-Object based on message content
    let msgFields ={};
    attributesArray.forEach((attribute) => {
        const {attributeName, attributeValue} = attribute;
        msgFields[attributeName] = attributeValue;
    });
    //Send Message
    return fetch(hostName + path, {
        headers: {
            "Accept": "application/json",
            "Authorization": 'Bearer ' + token,
	        "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(msgFields)
    })
    .then(function (response) {
        return response.status
    })
}

export async function sendNewPositions(auth, path, attributesArray) {
    let token = ""
    if (auth.user !== undefined) {
        token = auth.user.access_token
    } else {
        return ""
    }

    //Send Message
    return fetch(hostName + path, {
        headers: {
            "Accept": "application/json",
            "Authorization": 'Bearer ' + token,
	        "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify(attributesArray)
    })
    .then(function (response) {
        return response.status
    })
}

export async function changeMsgInfo(auth, path, newStatus) {
    let token = ""
    if (auth.user !== undefined) {
        token = auth.user.access_token
    } else {
        return ""
    }

    //Create JSON-Object based on information to update
    const updateFields = {
        status: newStatus
    };
    return fetch(hostName + path, {
        headers: {
            "Accept": "application/json",
            "Authorization": 'Bearer ' + token,
	        "Content-Type": "application/json"
        },
        method: "PATCH",
        body: JSON.stringify(updateFields)
    })
    .then(function (response) {
        return response.status
    })
}

export async function changeAssetWs(auth, path, newWs) {
    let token = ""
    if (auth.user !== undefined) {
        token = auth.user.access_token
    } else {
        return ""
    }

    //Create JSON-Object based on information to update
    const updateFields = {
        workstation_id: newWs
    };
    return fetch(hostName + path, {
        headers: {
            "Accept": "application/json",
            "Authorization": 'Bearer ' + token,
	        "Content-Type": "application/json"
        },
        method: "PATCH",
        body: JSON.stringify(updateFields)
    })
    .then(function (response) {
        return response.status
    })
}

export async function changeTicketInfo(auth, path, attributesArray, id) {
    let token = ""
    if (auth.user !== undefined) {
        token = auth.user.access_token
    } else {
        return ""
    }

    //Create JSON-Object based on information to update
    let updateFields = {};
    attributesArray.forEach((attribute) => {
        const {attributeName, attributeValue} = attribute;
        updateFields[attributeName] = attributeValue;
    });
    //updateFields[attributeName] = attributeValue;
    return fetch(hostName + path + id, {
        headers: {
            "Accept": "application/json",
            "Authorization": 'Bearer ' + token,
	        "Content-Type": "application/json"
        },
        method: "PATCH",
        body: JSON.stringify(updateFields)
    })
    .then(function (response) {
        return response.status
    })
}

export async function changeTicketOrderNoInfo(auth, attributesArray, id) {
    let token = ""
    if (auth.user !== undefined) {
        token = auth.user.access_token
    } else {
        return ""
    }

    //Create JSON-Object based on information to update
    let updateFields = {};
    attributesArray.forEach((attribute) => {
        const {attributeName, attributeValue} = attribute;
        updateFields[attributeName] = attributeValue;
    });
    //updateFields[attributeName] = attributeValue;
    return fetch(hostName + "/customers/tickets/" + id, {
        headers: {
            "Accept": "application/json",
            "Authorization": 'Bearer ' + token,
	        "Content-Type": "application/json"
        },
        method: "PATCH",
        body: JSON.stringify(updateFields)
    })
    .then(function (response) {
        return response.status
    })
}

export async function changeServiceInfo(auth, path, attributesArray) {
    let token = ""
    if (auth.user !== undefined) {
        token = auth.user.access_token
    } else {
        return ""
    }

    //Create JSON-Object based on information to update
    let updateFields = {};
    attributesArray.forEach((attribute) => {
        const {attributeName, attributeValue} = attribute;
        updateFields[attributeName] = attributeValue;
    });
    //updateFields[attributeName] = attributeValue;
    return fetch(hostName + path, {
        headers: {
            "Accept": "application/json",
            "Authorization": 'Bearer ' + token,
	        "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify(updateFields)
    })
    .then(function (response) {
        return response.status
    })
}

export async function createNewTicket(auth, path, attributesArray) {
    let token = ""
    if (auth.user !== undefined) {
        token = auth.user.access_token
    } else {
        return ""
    }

    //Create JSON-Object based on information to update
    let ticketFields ={};
    attributesArray.forEach((attribute) => {
        const {attributeName, attributeValue} = attribute;
        ticketFields[attributeName] = attributeValue;
    });
    return fetch(hostName + path, {
        headers:{
            "Accept": "application/json",
            "Authorization": 'Bearer ' + token,
	        "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(ticketFields)
    })
    .then(function (response) {
        return response.status
    })
}

export async function createNewRequest(auth, path, ticketArray) {
    let token = ""
    if (auth.user !== undefined) {
        token = auth.user.access_token
    } else {
        return ""
    }

    // Mapping ticketArray to ticketFields object
    const ticketFields = ticketArray.reduce((accumulator, { attributeName, attributeValue }) => {
        accumulator[attributeName] = attributeValue;
        return accumulator;
    }, {});

    // Mapping serviceArray to serviceFields object
    /* const serviceFields = serviceArray.reduce((accumulator, { attributeName, attributeValue }) => {
        accumulator[attributeName] = attributeValue;
        return accumulator;
    }, {}); */

    // Creating the final payload
    return fetch(hostName + path, {
        headers:{
            "Accept": "application/json",
            "Authorization": 'Bearer ' + token,
	        "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(ticketFields)
    })
    .then(function (response) {
        return response.status
    })
}

export async function payloadApi(auth, path, method, attributes) {
    return fetch(path, {
        method: method,
        body: JSON.stringify(attributes)
    })
    .then(function (response) {
        return response
    })
}

export async function getApi(auth, path) {
    let response = await fetch(path, {
        method: "GET"
    })
    return await response.json();
}

export async function deleteApi(auth, path) {
    let response = await fetch(path, {
        method: "DELETE"
    })
    return await response;
}
