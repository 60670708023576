import { useTranslation } from 'react-i18next';
import { Form, Modal, notification } from 'antd';
import {useEffect, useState} from "react";
import TicketCreateForm from "./Forms/TicketCreateForm";
import { createNewTicket } from '../../utils/RequestBuilder';

function AddTicketModal({isOpen, assets, userList, loggedInUser, auth, callbackFct}) {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    const handleOk = () => {
        form.validateFields().then(() => {
            handleTicketCreation(auth, createTicketArray(form.getFieldsValue()))

            setIsModalOpen(false)
            form.resetFields();
        })
    };


     //Show Ticket Create Alert
     const [api, contextHolder] = notification.useNotification();
     const openNotification = (placement, status, desc, title, errorCodeString) => {
         api[status]({
           message: title,
           description:
             <div>
                 <div>
                     {desc}
                 </div>
                 <div>
                     {errorCodeString}
                 </div>
             </div>
         });
     };
    function showCreateAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response == 200) {
            title = t('tickets.forms.create.create-message.title.success');
            desc = t('tickets.forms.create.create-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            
            title = t('tickets.forms.create.create-message.title.error');
            desc = t('tickets.forms.create.create-message.body.error');
            errorCode = '[' + t('tickets.forms.create.create-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    function createTicketArray(formData) {
        let moduleId = 0
        let issueId = 0

        if (formData.caseSelect !== undefined) {
            if (formData.caseSelect.length >= 1) {
                moduleId = formData.caseSelect[0]
            }
            if (formData.caseSelect.length >= 2) {
                issueId = formData.caseSelect[1]
            }
        }

        return [
            {attributeName: "title", attributeValue: formData.title},
            {attributeName: "description", attributeValue: formData.description},
            {attributeName: "priority", attributeValue: formData.priority},
            {attributeName: "asset_id", attributeValue: formData.asset},
            {attributeName: "assigned_to_id", attributeValue: formData.assigned_to},
            {attributeName: "status", attributeValue: formData.status},
            {attributeName: "service_module_id", attributeValue: moduleId},
            {attributeName: "service_issue_id", attributeValue: issueId},
            {attributeName: "service_type", attributeValue: formData.serviceSelect}
        ]
    }

    async function handleTicketCreation(auth, attributesArray) {
        try {
            const result = await createNewTicket(auth, "/tickets", attributesArray);
            showCreateAlert(result);
            if (result === 200) {
                callbackFct();
            }
        } catch (error) {
            console.error(error);
            //showAlert(error.status)
        }
    }


    const handleCancel = () => {
        setIsModalOpen(false)
    };

    useEffect(() => {
        setIsModalOpen(isOpen);
        // eslint-disable-next-line
    }, [isOpen])

    return (
        <>
            {contextHolder}
            <Modal
                className="tickets-modal-create"
                title={t('tickets.overview.add')}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <TicketCreateForm auth={auth} form={form} assets={assets} userList={userList} loggedInUser={loggedInUser} />
            </Modal>
        </>
    );
}

export default AddTicketModal;
