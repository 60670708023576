import { EditOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useEffect, useState } from "react";
import EditProductLineModal from "./EditProductLineModal";

function AssetTypesMenu({productLines, callbackFunc, callbackforEdit}) {

    const [formattedProductLines, setFormattedProductLines] = useState([]);
    const [currentProductLine, setCurrentProductLine] = useState();
    const [currentId, setCurrentId] = useState();
    const [xyz, setXyz] = useState(0);

    function openEditLineModal(e) {
        setXyz(prevXyz => prevXyz + 1)
    }

    function test(e) {
        setCurrentId(e.currentTarget.getAttribute("data-id"));
        setCurrentProductLine(e.currentTarget.getAttribute("data-name"));
        openEditLineModal(e);
        e.stopPropagation(); 
    }

    function formatProductLines() {
        let formatted = []
        productLines.forEach((productLine) => {
            let children = []

            if (productLine.asset_types.length > 0) {

                productLine.asset_types.forEach((assetType) => {
                    children.push({
                        key: productLine.product_line.id + "-" + assetType.id,
                        id: assetType.id,
                        label: assetType.name,
                        name: assetType.name
                    })
                })

            }

            formatted.push({
                key: productLine.product_line.id,
                id: productLine.product_line.id,
                label: 
                    <>
                        <EditOutlined 
                            data-name={productLine.product_line.name}
                            data-id={productLine.product_line.id}
                            onClick={(e) => test(e)} 
                        />
                        <span>  </span>
                        {productLine.product_line.name}  {" ("}  {children.length}  {")"}{" "}
                    </>,
                children: children,
                name: productLine.product_line.name
            })
        })
        setFormattedProductLines(formatted)
    }

    useEffect(() => {
        formatProductLines()
        // eslint-disable-next-line
    }, [productLines]);

    const handleItemClick = (key) => {
        callbackFunc(key);
      };

    return (
        <>
            <Menu
                onClick={(e) => callbackFunc(e.key)}
                style={{
                    width: 256,
                }}
                // defaultSelectedKeys={['1']}
                // defaultOpenKeys={['1']}
                mode="inline"
                items={formattedProductLines}
            >
            </Menu>
            <EditProductLineModal isOpen={xyz} currentLineName={currentProductLine} lineId={currentId} callbackFct={callbackforEdit} />
        </>
    );
}

export default AssetTypesMenu