import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {EditOutlined, DeleteOutlined, LinkOutlined, PartitionOutlined, FileImageFilled} from "@ant-design/icons";
import { Button, Tooltip, Space } from 'antd';
import React, { useState, useEffect } from "react";
import DocumentsArea from "../../utils/DocumentsArea";
import EditAssetModal from "./EditAssetModal";
import ChangeAssetAssignmentModal from "./ChangeAssetAssignmentModal";
import {
    userHasRole,
    USER_ROLE_MANUFACTURER_ENGINEER,
    USER_ROLE_ADMIN,
    USER_ROLE_SERVICE_MANAGER
} from "../../utils/userManagement";

const AssetDetails = ({ selectId, name, auth, id, type, type_id, manufacturer, equipment_number, callbackFct, setMenuItemKey,
                          assets, setForceRerender, customer }) => {

    const { t } = useTranslation();
    const hostName = process.env.REACT_APP_MANU_MANAGEMENT_URL
    const [editAssetModalIsOpen, setEditAssetModalIsOpen] = useState(0);
    const [changeAssetAssignmentModalIsOpen, setChangeAssetAssignmentModalIsOpen] = useState(0);
    const [hasAdminRole, setHasAdminRole] = useState(false);
    const [hasServiceManagerRole, setHasServiceManagerRole] = useState(false);
    const [imageProfile, setImageProfile] = useState("");

    async function loadImage() {
        try {
            const response = await fetch(hostName + "/asset-types/" + type_id + "/image", {
                method: "GET",
            })

            if (response.status === 200) {
                setImageProfile(response.url)
            } else if (response.status === 204) {
                setImageProfile("")
            }
        } catch (error) {
            console.error("Error requesting asset type image:", error);
            throw error;
        }
    }

    useEffect(() => {
        loadImage()
        // eslint-disable-next-line
    }, [type_id]);

    //Check Auth
    useEffect(() => {
        if (auth.user !== undefined) {
            setHasAdminRole(userHasRole(auth.user.profile, USER_ROLE_ADMIN))
            setHasServiceManagerRole(userHasRole(auth.user.profile, USER_ROLE_SERVICE_MANAGER))
        }
    }, [auth])

    const asset = {
        id: id,
        equipmentNumber: equipment_number,
        name: name,
        manufacturer: manufacturer,
        type: type
    }

    const openEditAssetModal = () => {
        setEditAssetModalIsOpen(editAssetModalIsOpen + 1)
    };

    const openChangeAssetAssignmentModal = () => {
        setChangeAssetAssignmentModalIsOpen(changeAssetAssignmentModalIsOpen + 1);
    }

    useEffect(() => {
        setMenuItemKey(selectId);
        // eslint-disable-next-line
    }, [selectId])
    

    return (
        <div>
            <Space 
                style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px 8px 0 0",
                    padding: "0 10px 0 10px"}}
            >
                <h2>
                    {asset.name} 
                    {hasAdminRole && (
                        <>
                            &nbsp;|&nbsp;
                            <Tooltip title={t('assets.overview.edit-asset-tooltip')}>
                                <Button
                                    className="asset-btn-round"
                                    ghost
                                    type="primary"
                                    shape="circle"
                                    onClick={() => openEditAssetModal()}
                                    icon={<EditOutlined />}
                                >
                                </Button>
                            </Tooltip>
                            &nbsp;|&nbsp;
                            <Tooltip title={t('assets.overview.change-asset-assignment-tooltip')}>
                                <Button
                                    className="asset-btn-round"
                                    ghost
                                    type="primary"
                                    shape="circle"
                                    onClick={() => openChangeAssetAssignmentModal()}
                                    icon={<PartitionOutlined />}
                                >
                                </Button>
                            </Tooltip>
                            &nbsp;|&nbsp;
                            <Tooltip title={t('assets.overview.delete-asset-tooltip')}>
                                <Button className="asset-btn-round" ghost disabled danger type="primary" shape="circle" icon={<DeleteOutlined />}></Button>
                            </Tooltip>
                        </>
                    )}
                </h2>
            </Space>
            <div 
                style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "0 8px 8px 8px",
                    padding: "0 10px 10px 10px",
                    marginBottom: "16px",
                    maxWidth: "425px"}}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {imageProfile !== "" ?
                        <img
                            alt="example"
                            src={imageProfile}
                            style={{
                                width: "240px",
                                margin: "8px 0"
                            }}
                        />
                        :
                        <FileImageFilled
                            style={{
                                fontSize: "96px",
                                margin: "80px 0"
                            }}
                        />
                    }
                </div>
                <table>
                    <tbody>
                        <tr>
                            <th align="right" style={{padding: "0 16px 0 16px"}}>{t('assets.details.manufacturer')}</th>
                            <td>{asset.manufacturer}</td>
                        </tr>
                        <tr>
                            <th align="right" style={{padding: "0 16px 0 16px"}}>{t('assets.details.type')}</th>
                            <td>{asset.type}</td>
                        </tr>
                        <tr>
                            <th align="right" style={{padding: "0 16px 0 16px"}}>{t('assets.details.equipment-number')}</th>
                            <td>{asset.equipmentNumber}</td>
                        </tr>
                        {hasServiceManagerRole && (
                            <tr>
                                <th align="right" style={{padding: "0 16px 0 16px"}}>{t('assets.details.tickets')}</th>
                                <td>
                                    <Link to={`/tickets?asset=${asset.id}`}>
                                        <Button
                                            type="primary"
                                            onClick={() => {}}
                                        >
                                            <LinkOutlined/>{t('assets.details.ticket-link')}
                                        </Button>
                                    </Link>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {hasServiceManagerRole && (
                <div
                    style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "8px",
                        padding: "0 10px 10px 10px",
                        marginTop: "16px",
                        maxWidth: "425px"}}
                >

                    <DocumentsArea
                        objectId={id}
                        auth={auth}
                        type={"asset"}
                        customerId={customer}
                    />
                </div>
            )}
            <EditAssetModal 
                isOpen={editAssetModalIsOpen} 
                auth={auth}
                id={asset.id} 
                name={asset.name} 
                type={type} 
                manufacturer={manufacturer} 
                equipment_number={equipment_number}
                callbackFct={callbackFct}
                setMenuItemKey={setMenuItemKey}
                selectId={selectId}
                customer={customer}
            />
            <ChangeAssetAssignmentModal 
                isOpen={changeAssetAssignmentModalIsOpen}
                auth={auth}
                assetList={assets}
                id={asset.id}
                setForceRerender={setForceRerender}
                customer={customer}
            />
        </div>
    )
}

export default AssetDetails;