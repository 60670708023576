import {Col, List, Space} from "antd";
import {Scrollbars} from "react-custom-scrollbars-2";
import TicketKanbanCard from "../Tickets/TicketKanbanCard";
import {useTranslation} from "react-i18next";

function RequestKanbanLists({tickets, header, hasManufacturerRole}) {

    const { t } = useTranslation();

    return (
        <Col className="tickets-kanban-column" span={8}>
            <Space className="tickets-status-columns-header"
                   style={{
                       display: 'flex',
                       justifyContent: 'space-between',

                   }}
            >
                <h3>{header} <span>({tickets.length} {t('requests.title')})</span></h3>
            </Space>
            <div className="border-radius-setting"
                 style={{
                     paddingBottom: "15px",
                     paddingTop: "8px",
                     backgroundColor: "#f5f5f5",
                     

                 }}
            >
                <Scrollbars
                    autohide="true"
                    style={{
                        height: `calc(100vh - 260px)`,
                    }}>
                    <List
                        className={"ticket-list"}
                        split='false'
                        dataSource={tickets}
                        height={750}
                        renderItem={(item)=> (
                            <List.Item key={item.id}>
                                <TicketKanbanCard
                                    key={item.id}
                                    id={item.id}
                                    name={item.title}
                                    asset={item.asset}
                                    asset_type={item.asset_type_name}
                                    created_at={item.created_at}
                                    updated_at={item.updated_at}
                                    closed_at={item.closed_at}
                                    description={item.description}
                                    assigned_to={item.manufacturer_engineer_name}
                                    assigned_to_signature={item.manufacturer_engineer_signature}
                                    customer={item.customer_name}
                                    hasManufacturerRole={hasManufacturerRole} />
                            </List.Item>
                        )}
                    />
                </Scrollbars>
            </div>
        </Col>
    )
}
export default RequestKanbanLists;
