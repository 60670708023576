import {Button, Checkbox, Input, Tabs, Space, Form, notification, Upload} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getApi, payloadApi } from "../../../utils/RequestBuilder";
import AssignmentForm from "./AssignmentForm";
import ImgCrop from "antd-img-crop";

function AssetTypeForm({id, modules, issues, callbackFunc}) {
    const { t } = useTranslation();
    const hostName = process.env.REACT_APP_MANU_MANAGEMENT_URL

    const [enableImageUpdate, setEnableImageUpdate] = useState(false)
    const [imageForm] = Form.useForm()
    const [fileList, setFileList] = useState([]);

    const [nameForm] = Form.useForm()
    const [serviceForm] = Form.useForm()
    const [assetType, setAssetType] = useState({
        id: 0,
        name: "",
        service_guided_enabled: false,
        service_onsite_enabled: false,
        service_remote_enabled: false,
        service_personal_enabled: false,
    })
    const [activeTab, setActiveTab] = useState(null)
    const [isAssignmentFormDisabled, setIsAssignmentFormDisabled] = useState(false);

    //Show Entry Save Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };

    async function loadAssetType(auth) {
        if (id > 0) {
            try {
                const result = await getApi(auth, hostName + "/asset-types/" + id);
                setAssetType(result)
            } catch (error) {
                console.log(error)
            }
        }
    }

    async function handleUpdate(auth, payload) {
        try {
            const result = await payloadApi(
                auth,
                hostName + "/asset-types/" + id,
                "PUT",
                payload
            );
            callbackFunc()
            setActiveTab(getActiveTab())
            return result.status
        } catch (error) {
            console.log(error)
        }
    }

    function showCreateAlert(response, field) {
        let desc = "";
        let title = "";
        let errorCode = "";
        switch (field) {
            case "image": {
                if (response === 200) {
                    title = t('administration.asset-types.form.edit.asset-type.image.save-message.title.success');
                    desc = t('administration.asset-types.form.edit.asset-type.image.save-message.body.success');
                    openNotification('topRight', 'success', desc, title, errorCode)
                } else {
                    title = t('administration.asset-types.form.edit.asset-type.image.save-message.title.error');
                    desc = t('administration.asset-types.form.edit.asset-type.image.save-message.body.error');
                    errorCode = '[' + t('administration.asset-types.form.edit.image.asset-type.save-message.status-code') + ': ' + response + ']'
                    openNotification('topRight', 'error', desc, title, errorCode)
                }
            }
            case "name": {
                if (response === 200) {
                    title = t('administration.asset-types.form.edit.asset-type.name.save-message.title.success');
                    desc = t('administration.asset-types.form.edit.asset-type.name.save-message.body.success');
                    openNotification('topRight', 'success', desc, title, errorCode)
                } else {
                    title = t('administration.asset-types.form.edit.asset-type.name.save-message.title.error');
                    desc = t('administration.asset-types.form.edit.asset-type.name.save-message.body.error');
                    errorCode = '[' + t('administration.asset-types.form.edit.name.asset-type.save-message.status-code') + ': ' + response + ']'
                    openNotification('topRight', 'error', desc, title, errorCode)
                }
            }
                break;
            case "services": {
                if (response === 200) {
                    title = t('administration.asset-types.form.edit.asset-type.services.save-message.title.success');
                    desc = t('administration.asset-types.form.edit.asset-type.services.save-message.body.success');
                    openNotification('topRight', 'success', desc, title, errorCode)
                } else {
                    title = t('administration.asset-types.form.edit.asset-type.services.save-message.title.error');
                    desc = t('administration.asset-types.form.edit.asset-type.services.save-message.body.error');
                    errorCode = '[' + t('administration.asset-types.form.edit.services.asset-type.save-message.status-code') + ': ' + response + ']'
                    openNotification('topRight', 'error', desc, title, errorCode)
                }
            }
                break;
            default:
                break;
        }
    }

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const onChangeImage = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        if (newFileList.length > 0) {
            setEnableImageUpdate(true)
        } else {
            setEnableImageUpdate(false)
        }

    };

    async function onEditImage() {
        console.log(fileList)

        let status = await sendRequestWithAttachment(fileList[0].originFileObj)

        imageForm.resetFields()
        showCreateAlert(status, "image")
    }

    async function sendRequestWithAttachment(imageFile) {
        // form data
        let formData = new FormData()

        // append picture
        formData.append('image', imageFile)

        // send message
        try {
            const response = await fetch(hostName + "/asset-types/" + id + "/image", {
                method: "PUT",
                body: formData,
                contentType: "multipart/form-data",
            });

            return response.status;
        } catch (error) {
            console.error("Error sending new message:", error);
            // Handle error
            throw error;
        }
    }

    async function onEditName() {
        let asset = assetType
        const formValues = nameForm.getFieldsValue();
        asset.name = formValues.name
        const status = await handleUpdate("",asset)
        showCreateAlert(status, "name")
    }

    async function onEditService() {
        let asset = assetType
        const formValues = serviceForm.getFieldsValue();
        asset.service_guided_enabled = formValues.guided;
        asset.service_onsite_enabled = formValues.onsite;
        asset.service_remote_enabled = formValues.remote;
        asset.service_personal_enabled = formValues.personal;
        const status = await handleUpdate("", asset);
        showCreateAlert(status, "services");
        setIsAssignmentFormDisabled(false);
    }

    async function loadAssetTypeImage() {
        if (id === 0) {
            return
        }
        try {
            const response = await fetch(hostName + "/asset-types/" + id + "/image", {
                method: "GET",
            })

            if (response.status === 200) {
                setFileList([
                    {
                        name: 'image.png',
                        status: 'done',
                        url: response.url,
                    },
                ])
            } else if (response.status === 204) {
                setFileList([])
            }
        } catch (error) {
            console.error("Error requesting asset type image:", error);
            throw error;
        }
    }

    useEffect(() => {
        nameForm.setFieldsValue({
            name: assetType.name,
        })
        serviceForm.setFieldsValue({
            guided: assetType.service_guided_enabled,
            onsite: assetType.service_onsite_enabled,
            remote: assetType.service_remote_enabled,
            personal: assetType.service_personal_enabled,
        })
        setActiveTab(getActiveTab())

        loadAssetTypeImage()

        // eslint-disable-next-line
    }, [assetType])

    useEffect(() => {
        loadAssetType()
        // eslint-disable-next-line
    }, [id])

    const getActiveTab = () => {
        if (assetType.service_guided_enabled) return "1";
        if (assetType.service_onsite_enabled) return "2";
        if (assetType.service_remote_enabled) return "3";
        if (assetType.service_personal_enabled) return "4";
        return null;
      };

      const isFormDisabled = assetType.id === 0

    return (
        <>
            {contextHolder}
            <div>
                <h3 style={{marginTop: "8px"}}>{t('administration.asset-types.title')}</h3>
                <br />
                <Space direction="vertical" size="large">
                    <Space size="middle">
                        <b>{t('administration.asset-types.form.image')}:</b>
                        <Form
                            autoComplete="off"
                            name="image"
                            form={imageForm}
                        >
                            <Form.Item
                                name="asset_type_image"
                            >
                                <ImgCrop zoomSlider>
                                    <Upload
                                        listType="picture-card"
                                        fileList={fileList}
                                        onChange={onChangeImage}
                                        onPreview={onPreview}
                                        disabled={isFormDisabled}
                                    >
                                        {fileList.length < 1 && '+ Upload'}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </Form>
                        <Button
                            disabled={!enableImageUpdate}
                            type="primary"
                            onClick={() => onEditImage()}
                        >
                            <SaveOutlined/>{t('administration.asset-types.form.edit.image')}
                        </Button>
                    </Space>
                    <Space size="middle">
                        <b>{t('administration.asset-types.form.name')}:</b>
                        <Form
                            autoComplete="off"
                            name="basic"
                            form={nameForm}
                            disabled={isFormDisabled}
                        >
                            <Form.Item
                                name="name"
                                rules={[{
                                    required: true,
                                }]}
                                style={{marginBottom: "0px"}}
                            >
                                <Input style={{minWidth: "350px"}} placeholder={t('administration.asset-types.form.placeholder.name')}/>
                            </Form.Item>
                        </Form>
                        <Button
                            type="primary"
                            onClick={() => onEditName()}
                            disabled={isFormDisabled}
                        >
                            <SaveOutlined/>{t('administration.asset-types.form.edit.name')}
                        </Button>
                    </Space>
                    <Space size="middle">
                        <b>{t('administration.asset-types.form.service-types')}:</b>
                        <div>
                            <Form
                                autoComplete="off"
                                name="basic"
                                form={serviceForm}
                                disabled={isFormDisabled}
                                onValuesChange={() => {setIsAssignmentFormDisabled(true); setActiveTab(null)}}
                            >
                                <Form.Item
                                    name="guided"
                                    valuePropName="checked"
                                    style={{marginBottom: "0px"}}
                                >
                                    <Checkbox>{t('administration.service-types.guided')}</Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="onsite"
                                    valuePropName="checked"
                                    style={{marginBottom: "0px"}}
                                >
                                    <Checkbox>{t('administration.service-types.onsite')}</Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="remote"
                                    valuePropName="checked"
                                    style={{marginBottom: "0px"}}
                                >
                                    <Checkbox>{t('administration.service-types.remote')}</Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="personal"
                                    valuePropName="checked"
                                    style={{marginBottom: "0px"}}
                                >
                                    <Checkbox>{t('administration.service-types.personal')}</Checkbox>
                                </Form.Item>
                            </Form>
                        </div>
                        <Button
                            type="primary"
                            onClick={() => onEditService()}
                            disabled={isFormDisabled}
                        >
                            <SaveOutlined/>{t('administration.asset-types.form.edit.services')}
                        </Button>
                    </Space>
                    <Tabs
                        activeKey={activeTab}
                        onChange={setActiveTab}
                        items={[
                            {
                                key: '1',
                                label: t('administration.service-types.guided'),
                                children: <AssignmentForm assetTypeId={id} serviceType={'guided'} modules={modules} issues={issues} />,
                                disabled: !assetType.service_guided_enabled || isAssignmentFormDisabled,
                            },
                            {
                                key: '2',
                                label: t('administration.service-types.onsite'),
                                children: <AssignmentForm assetTypeId={id} serviceType={'onsite'} modules={modules} issues={issues} />,
                                disabled: !assetType.service_onsite_enabled || isAssignmentFormDisabled,
                            },
                            {
                                key: '3',
                                label: t('administration.service-types.remote'),
                                children: <AssignmentForm assetTypeId={id} serviceType={'remote'} modules={modules} issues={issues} />,
                                disabled: !assetType.service_remote_enabled || isAssignmentFormDisabled,
                            },
                            {
                                key: '4',
                                label: t('administration.service-types.personal'),
                                children: <AssignmentForm assetTypeId={id} serviceType={'personal'} modules={modules} issues={issues} />,
                                disabled: !assetType.service_personal_enabled || isAssignmentFormDisabled,
                            },
                        ]}
                        // onChange={onChange}
                    />
                </Space>
            </div>
        </>
    );
}

export default AssetTypeForm