import { useTranslation } from 'react-i18next';
import { Modal, notification } from 'antd';
import {useEffect, useState} from "react";
import PositionTree from './PositionTree';
import { sendNewPositions } from '../../utils/RequestBuilder';

function ChangeWorkstationPositionModal({isOpen, auth, setSelection, externalSetKey, assetList, menuItemKey,
                                            setForceRerender, customer}) {

    const { t } = useTranslation();
    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const [ newPositionArray, setNewPositionArray] = useState([])
    const [ reRender, setReRender] = useState(0)


    //Show Ticket Create Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };

   function showCreateAlert(response) {
       let desc = "";
       let title = "";
       let errorCode = "";
       if (response == 200) {
           title = t('assets.forms.ws-position.ws-position-message.title.success');
           desc = t('assets.forms.ws-position.ws-position-message.body.success');
           openNotification('topRight', 'success', desc, title, errorCode)
       } else {
           title = t('assets.forms.ws-position.ws-position-message.title.error');
           desc = t('assets.forms.ws-position.ws-position-message.body.error');
           errorCode = '[' + t('assets.forms.ws-position.ws-position-message.status-code') + ': ' + response + ']'
           openNotification('topRight', 'error', desc, title, errorCode)
       }
   }

   async function handlePositionChange() {
    let result;
    try {
        result = await sendNewPositions(auth, "/customers/" + customer + "/workstations/positions", newPositionArray )
        showCreateAlert(result);
    } catch (error) {
        console.error(error);
    }
   }

    const handleOk = () => {
        setIsModalOpen(false);
        handlePositionChange();
        let renderCounter = reRender;
        renderCounter += 1;
        setReRender(renderCounter);
        setForceRerender(renderCounter);  
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    useEffect(() => {
        setIsModalOpen(isOpen);
        // eslint-disable-next-line
    }, [isOpen])

    return (
        <>
            {contextHolder}
            <Modal
                title={<>{t('assets.overview.change-ws-position-title')}{" "}<span className="ant-form-text" style={{opacity: 0.4}}>{"| "}{t('assets.overview.drag-and-drop')}</span></>}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <br/>
                <PositionTree 
                    setSelection={setSelection} 
                    externalSetKey={externalSetKey} 
                    assetList={assetList} 
                    menuItemKey={menuItemKey} 
                    setNewPositionArray={setNewPositionArray}
                    type="workstation"
                    reload={isModalOpen}
                />
            </Modal>
        </>
    )

}

export default ChangeWorkstationPositionModal;