import { Link } from "react-router-dom";
import { Card, Badge, Avatar, Row, Col, Space, Button, Divider, Typography, Tag, Tooltip } from 'antd';
import { getDateFormat, getAvatarColor } from "../../utils/utils";
import dayjs from 'dayjs';
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined, EllipsisOutlined, SettingOutlined, UserOutlined, CloseOutlined, DeleteOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
const { Meta } = Card;
const { Text } = Typography;

function TicketKanbanCard({
    id, 
    name,
    asset,
    asset_type, 
    description, 
    assigned_to, 
    assigned_to_signature,
    updated_at,
    customer,
    has_service,
    hasManufacturerRole
    }) {

    const [manufacturerName, setManufacturerName] = useState(assigned_to);
    const [manufacturerSignature, setManufacturerSignature] = useState(assigned_to_signature);

    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        };

    useEffect(() => {
        if(assigned_to === "") {
            setManufacturerName(t('requests.overview.not-assigned'));
        }
        if(assigned_to_signature === "") {
            setManufacturerSignature("--");
        }
        
    }, [assigned_to, assigned_to_signature])

    const renderTag = (hasService, t) => {
        if (!hasManufacturerRole) {
            if (hasService) {
                return (
                    <Tooltip title={t('tickets.overview.tooltip.access')}>
                        <Tag color="green"><EyeOutlined /></Tag>
                    </Tooltip>
                );
            } else {
                return (
                    <Tooltip title={t('tickets.overview.tooltip.no-access')}>
                        <Tag><EyeInvisibleOutlined /></Tag>
                    </Tooltip>
                )
            }
        } else return (<></>);
    }

    return (
        <div>
            <Card
                className="tickets-kanban-card"
                /*actions={[
                    <SettingOutlined key="setting" />,
                    <EditOutlined key="edit" />,
                    <EllipsisOutlined key="ellipsis" />,
                ]}*/
                
            >   
                <Meta
                    className="tickets-kanban-card-name"
                    title={
                        <div>
                            {
                                hasManufacturerRole ? (
                                    <>
                                        <div style={{opacity: '0.4'}}>
                                                {customer !== undefined ? (
                                                    asset + " | " + asset_type + " | " + customer 
                                                    ) : (asset)
                                                }
                                        </div>  
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'}}
                                        >   
                                            <div>
                                                {/* {renderTag(has_service, t)} */}
                                                <Link to={`/tickets/${id}`}>
                                                    {name.length > 70 ?
                                                        `${name.substring(0, 70)}...` : name}
                                                </Link>
                                            </div>
                                            {/* <CloseOutlined/> */}
                                        </div>
                                    </>
                                ) : ( 
                                    <>  
                                        <div style={{opacity: '0.4'}}>
                                            {customer !== undefined ? (
                                                asset + " | " + asset_type 
                                                ) : (asset)
                                            }
                                        </div>  
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'}}
                                        >   
                                            <div>
                                                {/* {renderTag(has_service, t)} */}
                                                <Link to={`/tickets/${id}`}>
                                                    {name.length > 65 ?
                                                        `${name.substring(0, 65)}...` : name}
                                                </Link>
                                            </div>
                                            {/* <CloseOutlined/> */}
                                        </div>
                                    </>
                                )
                            }
                            <Divider style={{margin: '12px 0px 0px 0px'}} />
                            <p style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                whiteSpace: 'normal',
                            }}>
                                {description.length > 140 ?
                                    `${description.substring(0, 140)}...` : description}
                            </p>
                        </div>
                    }
                    description={
                        <div>
                            <Divider style={{margin: '0px 0px 12px 0px'}} />
                            <Row justify="space-between" align="middle">
                                <Col span={12} >
                                    <div>Updated at: {dayjs(updated_at).format(getDateFormat(i18n.language))}</div>
                                </Col>
                                <Col >
                                    <Avatar style={{ backgroundColor: getAvatarColor(manufacturerSignature)}}>{manufacturerSignature}</Avatar> {manufacturerName}
                                </Col>
                            </Row>
                        </div>
                    }    
                />  
            </Card>
        </div>
    )
}

export default TicketKanbanCard;
