import React, { useState, useRef, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Divider, Input, Select, Space, Button } from 'antd';

let index = 0;

function AssetTypeDropdown({assetTypeList, callbackFctId, callbackFctName}) {

    const { t } = useTranslation();

    const [items, setItems] = useState([]);
    const [name, setName] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        setItems(assetTypeList)
    }, [assetTypeList])

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const onItemSelected = (selectedValue) => {
        callbackFctId(selectedValue)
    }
    const addItem = (e) => {
        callbackFctName(name)
        e.preventDefault();
        setItems(
            [...items,
            {product_line:
                {name: t('Others')}, 
                asset_types: [{name: name, id: name}]} || `New item ${index++}`]);
        setName('');
        setTimeout(() => {
        inputRef.current?.focus();
        }, 0);
    };
    return (
        <Select
        style={{
            width: 300,
        }}
        placeholder={t('assets.details.placeholder.asset-type')}
        dropdownRender={(menu) => (
            <>
            {menu}
            <Divider
                style={{
                margin: '8px 0',
                }}
            />
            <Space
                style={{
                padding: '0 8px 4px',
                }}
            >
                <Input
                placeholder={t('assets.details.placeholder.add-manufacturer')}
                ref={inputRef}
                value={name}
                onChange={onNameChange}
                onKeyDown={(e) => e.stopPropagation()}
                />
                <Button type="text" icon={<PlusOutlined />} onClick={addItem} disabled={!name}>
                {t('assets.details.add-button')}
                </Button>
            </Space>
            </>
        )}
        options={
            items.map((item) => ({
                label: item.product_line.name,
                options: item.asset_types.map(asset => ({
                        label: asset.name,
                        value: asset.id
                })),
            }))
        }
        onChange={onItemSelected}
        
        />
    );
};
export default AssetTypeDropdown;