import {Avatar, List, Tag} from "antd";
import {getAvatarColor, getDateFormat} from "../../utils/utils";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function NotificationListItem({item, message}) {

    const { t, i18n } = useTranslation();

    function timeFromCreation(created_at) {
        var a = dayjs()
        return dayjs(created_at).from(a, true);
    }

    return (
        <Link to={item.target}>
            <List.Item style={{backgroundColor: 'white', minWidth: 500, paddingBottom: 0}}>
                <List.Item.Meta
                    style={{alignItems: "center", paddingBottom: "5px", borderBottom: "1px solid rgba(5, 5, 5, 0.06)"}}
                    avatar={<Avatar style={{marginBottom: 0, backgroundColor: getAvatarColor(item.actor_signature)}}>{item.actor_signature}</Avatar>}
                    title={item.scope_description}
                    description={
                        <>
                            <span><b>{item.actor}</b></span>{": "}{message}
                            <br/>
                            <span style={{marginRight: 8}}><i>{dayjs(item.time).format(getDateFormat(i18n.language))}</i></span><Tag>{timeFromCreation(item.time)}</Tag>
                        </>
                    }
                />
            </List.Item>
        </Link>
    )
}

export default NotificationListItem