import { useAuth } from "react-oidc-context";

function Test() {

    const auth = useAuth();

    return (
        <div>
            <p>Hallo Welt</p>
            <button onClick={() => {void auth.removeUser(); auth.signoutRedirect({id_token_hint: auth.user.id_token})}}>Log out</button>
        </div>
    );
}

export default Test;
