import React, {useEffect, useState} from 'react';
import { Card } from 'antd';
import {FileImageFilled} from "@ant-design/icons";


const { Meta } = Card;
const AssetCard = ({ selectId, name, setSelection, workstationName, position, internal_identifier, id, type, type_id, manufacturer, equipment_number, ticketCount}) => {

    const hostName = process.env.REACT_APP_MANU_MANAGEMENT_URL

    const select = () => {
        setSelection(selectId, name, "asset", internal_identifier, id, position, type, type_id, manufacturer, equipment_number)
    }

    const [imageProfile, setImageProfile] = useState("");

    async function loadImage() {
        try {
            const response = await fetch(hostName + "/asset-types/" + type_id + "/image", {
                method: "GET",
            })

            if (response.status === 200) {
                setImageProfile(response.url)
            } else if (response.status === 204) {
                setImageProfile("")
            }
        } catch (error) {
            console.error("Error requesting asset type image:", error);
            throw error;
        }
    }

    useEffect(() => {
        loadImage()
        // eslint-disable-next-line
    }, [type_id]);

    return (
        <Card
            hoverable
            style={{
                width: 240,
                marginTop: "8px",
                marginLeft: "16px",
                marginBottom: "8px"
            }}
            cover={imageProfile !== "" ? <img alt="example" src={imageProfile}/> : <FileImageFilled style={{fontSize: "80px", margin: "80px 0"}} />}
            onClick={() => select()}
        >
            <Meta
                title={name}
                description={"Tickets: " +  ticketCount}
            />
        </Card>
    )
}

export default AssetCard;