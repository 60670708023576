import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, notification, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import {useEffect, useState} from "react";
import { changeTicketInfo } from '../../utils/RequestBuilder';
import { wait } from '@testing-library/user-event/dist/utils';

function EditAssetModal({isOpen, auth, id, name, type, manufacturer, equipment_number, callbackFct, setMenuItemKey,
                            selectId, customer}) {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm();
  

    const handleOk = () => {
        form.validateFields().then(() => {
            const formValues = form.getFieldsValue();
            const WsInformationArray = createWsArray(formValues);
            setMenuItemKey(selectId)
            handleWsCreation(auth, WsInformationArray)
            
            form.resetFields();
        })
    };

    function createWsArray(formData) {
        const WsInformationArray = [
            {attributeName: "name", attributeValue: formData.name},
            {attributeName: "manufacturer", attributeValue: formData.manufacturer},
            {attributeName: "type", attributeValue: formData.type},
            {attributeName: "equipment_number", attributeValue: formData.eq_no},
        ]
        return WsInformationArray
    }

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    }

     //Show Ticket Create Alert
     const [api, contextHolder] = notification.useNotification();
     const openNotification = (placement, status, desc, title, errorCodeString) => {
         api[status]({
           message: title,
           description:
             <div>
                 <div>
                     {desc}
                 </div>
                 <div>
                     {errorCodeString}
                 </div>
             </div>
         });
     };

    function showCreateAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response === 200) {
            title = t('assets.forms.edit.asset.update-message.title.success');
            desc = t('assets.forms.edit.asset.update-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            title = t('assets.forms.edit.asset.update-message.title.error');
            desc = t('assets.forms.edit.asset.update-message.body.error');
            errorCode = '[' + t('assets.forms.update.asset.update-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    async function handleWsCreation(auth, attributesArray) {
        try {
            const result = await changeTicketInfo(auth, "/customers/" + customer + "/assets/", attributesArray, id);
            showCreateAlert(result);
            if (result === 200) {
                setIsModalOpen(false);
                setMenuItemKey(selectId);
                callbackFct();}
        } catch (error) {
            console.error(error);
        }
    }

    const onFinish = (values) => {
        form.resetFields();
    }
     
    const handleCancel = () => {
        setIsModalOpen(false)
        form.resetFields();
    };

    async function getAssetImage(id) {
        try {
            //const response = await fetch('https://placebear.com/g/200/200')
            //return response.data.imageUrl; 
            return 'https://placebear.com/g/200/200'
        } catch (error) {
            console.error('Error fetching asset image:', error);
            throw error;
        }
    }

    useEffect(() => {
        setIsModalOpen(isOpen);
        if (name !== undefined) {
            form.setFieldsValue({
                name: name,
                manufacturer: manufacturer,
                type: type,
                eq_no: equipment_number
            });
        getAssetImage(id).then(imageUrl => {
            if (imageUrl) {
                setFileList([{ uid: '-1', name: 'image.png', status: 'done', url: imageUrl }]);
            }
        }).catch(error => {
            console.error('Error fetching asset image:', error);
        });
        }
        // eslint-disable-next-line
    }, [isOpen, form])

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
          src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
      };

      const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image.jpg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            notification.error({
                message: t('assets.forms.create.asset.image.error'),
                description: t('assets.forms.create.asset.image.type-error'),
            });
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            notification.error({
                message: t('assets.forms.create.asset.image.error'),
                description: t('assets.forms.create.asset.image.size-error'),
            });
        }
        return (isJpgOrPng && isLt10M) || Upload.LIST_IGNORE;
    };

    return (
        <>
            {contextHolder}
            <Modal
                className="tickets-modal-create"
                title={t('assets.forms.edit.asset.title')}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    autoComplete="off"
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        name: name,
                        manufacturer: manufacturer,
                        type: type,
                        eq_no: equipment_number
                    }}
                >
                    <Form.Item
                        label={t('assets.forms.edit.asset.labels.name')}
                        name="name"
                        rules={[{
                            required: true,
                            message: t('assets.forms.edit.asset.labels.name') + ' ' + t('assets.forms.error-message')
                        }]} 
                    >
                        <Input  />
                    </Form.Item>
                    <Form.Item
                        label={t('assets.forms.edit.asset.labels.manufacturer')}
                        name="manufacturer"
                        rules={[{
                             required: true,
                             message: t('assets.forms.edit.asset.labels.manufacturer') + ' ' + t('assets.forms.error-message')
                        }]} 
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                         label={t('assets.forms.edit.asset.labels.type')}
                         name="type"
                         rules={[{
                             required: true,
                             message: t('assets.forms.edit.asset.labels.type') + ' ' + t('assets.forms.error-message')
                         }]} 
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                         label={t('assets.forms.edit.asset.labels.eq-no')}
                         name="eq_no"
                         rules={[{
                             required: true,
                             message: t('assets.forms.edit.asset.labels.eq-no') + ' ' + t('assets.forms.error-message')
                         }]} 
                    >
                        <Input />
                    </Form.Item>
                    {/*
                    <Form.Item
                        label={t('assets.forms.create.asset.labels.assetImg')}
                        name="assetImgCrop"
                    >
                        <ImgCrop zoomSlider>
                            <Upload
                                //action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                                customRequest={dummyRequest}
                                beforeUpload={beforeUpload}
                            >
                                {fileList.length < 1 && '+ Upload'}
                            </Upload>
                        </ImgCrop>
                    </Form.Item>
                    */}
                </Form>
            </Modal>
        </>
    );
}

export default EditAssetModal;