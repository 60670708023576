import {Link} from "react-router-dom";
import {useEffect, useState, useContext} from "react";
import { useTranslation } from 'react-i18next';
import { Dropdown, Badge, List, notification } from 'antd';
import { MailOutlined } from "@ant-design/icons";
import { useAuth} from "react-oidc-context";
import { GlobalContext } from "../../utils/GlobalContext";
import { userHasRole, USER_ROLE_MANUFACTURER_ENGINEER } from "../../utils/userManagement";
import { fetchApi } from '../../utils/RequestBuilder';
import NotificationListItem from "./NotificationListItem";

function UnreadNotificationList() {

    const { t, i18n } = useTranslation();
    const auth = useAuth();

    const [api, contextHolder] = notification.useNotification();
    const  notificationContext  = useContext(GlobalContext);
    const [hasServiceManagerRole, setRole] = useState(false);
    const [unreadNotificationsList, setUnreadNotificationsList] = useState([]);


    async function loadUnreadMessages() {
        try {
            const jsonData = await fetchApi(auth, "/notifications");
            setUnreadNotificationsList(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    //Show push notification (Chat)
    const openPushNotificationChat = (placement, status, desc, title, ticketId, msgOrigin) => {
        api[status]({
            duration: 6.5,
            message: title,
            description:
                <div>
                    <div>
                        <b>{msgOrigin}</b> : {desc}
                    </div>
                    <br/>
                    <div>
                        <Link to={`/tickets/${ticketId}`}>
                            {t('tickets.details.push.link')}
                        </Link>
                    </div>
                </div>
        });
    };

    //Show push notification (Time Booking)
    const openPushNotificationTimeBooking = (placement, status, desc, title, target, actor) => {
        api[status]({
            duration: 6.5,
            message: title,
            description:
                <div>
                    <div>
                        <b>{actor}</b> : {desc}
                    </div>
                    <br/>
                    <div>
                        <Link to={target}>
                            {t('tickets.details.push.link')}
                        </Link>
                    </div>
                </div>
        });
    };

    //Show push notification (Ticket)
    const openPushNotificationTicket = (placement, status, desc, title, target) => {
        api[status]({
            duration: 6.5,
            message: title,
            description:
                <div>
                    <div>
                        <b>{t('tickets.fly-in.ticket.create.system')}</b> : {desc}
                    </div>
                    <br/>
                    <div>
                        <Link to={target}>
                            {t('tickets.details.push.link')}
                        </Link>
                    </div>
                </div>
        });
    };

    //Show Pushnotifications for incoming ChatMessages
    function showPushNotificationChat(msgString, ticketId, msgSender) {
        let desc = msgString.length > 50 ? `${msgString.substring(0, 50)}...` : msgString
        let title = t('tickets.details.push.title');
        let msgOrigin = msgSender;
        openPushNotificationChat('topRight', 'info', desc, title, ticketId, msgOrigin)
    }

    //Show Pushnotifications for incoming Time Booking Messages
    function showPushNotificationTimeBooking(actor, target) {
        let desc = t('tickets.fly-in.time-booking.info-text');
        let title = t('tickets.details.push.title');
        openPushNotificationTimeBooking('topRight', 'info', desc, title, target, actor)
    }

    //Show push notifications for incoming Ticket Creation Messages
    function showPushNotificationTicketCreate(target) {
        let desc = t('tickets.fly-in.ticket.create.info-text');
        let title = t('tickets.details.push.title');
        openPushNotificationTicket('topRight', 'info', desc, title, target)
    }

    //Show push notifications for Ticket Assigned changed
    function showPushNotificationTicketAssigned(target) {
        let desc = t('tickets.fly-in.ticket.assigned.info-text');
        let title = t('tickets.details.push.title');
        openPushNotificationTicket('topRight', 'info', desc, title, target)
    }

      //Show push notifications for incoming Ticket Owner Update Messages
      function showPushNotificationTicketOwner(target) {
        let desc = t('tickets.fly-in.ticket.owner.info-text');
        let title = t('tickets.details.push.title');
        openPushNotificationTicket('topRight', 'info', desc, title, target)
    }

    useEffect(() => {;
        loadUnreadMessages()
    },[])

    useEffect(() => {
        if (auth.user !== undefined) {
            setRole(userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER))
        }
        // eslint-disable-next-line
    }, [auth])

     //Handle Context [WS] change
    useEffect(() => {
        if ("event" in notificationContext) {
            switch (notificationContext.event) {
                case "message_created":
                    if ((notificationContext.payload.participant === "manufacturer" && !hasServiceManagerRole) || (notificationContext.payload.participant === "customer" && hasServiceManagerRole)) {
                        if (notificationContext.sendPush) {
                            showPushNotificationChat(
                                notificationContext.payload.text,
                                notificationContext.relation_id,
                                notificationContext.payload.title,
                            );
                            notificationContext.sendPush = false;
                        }
                    }
                    loadUnreadMessages();
                    break;
                case "time_booking_created":
                    if (notificationContext.sendPush) {
                        showPushNotificationTimeBooking(
                            notificationContext.payload.actor,
                            notificationContext.payload.target
                        )
                    }
                    loadUnreadMessages();
                    break;
                case "notifications_updated":
                    loadUnreadMessages();
                    break;
                case "message_updated":
                    loadUnreadMessages();
                    break;
                case "ticket_created":
                    if (notificationContext.sendPush) {
                        showPushNotificationTicketCreate(
                            notificationContext.payload.target
                        )
                    }
                    loadUnreadMessages();
                    break;
                case "ticket_owner_updated":
                    if (notificationContext.sendPush) {
                        showPushNotificationTicketOwner(
                            notificationContext.payload.target
                        )
                    }
                    loadUnreadMessages();
                    break;
                case "ticket_manufacturer_owner_updated":
                    if (notificationContext.sendPush) {
                        showPushNotificationTicketAssigned(
                            notificationContext.payload.target
                        )
                    }
                    loadUnreadMessages();
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationContext]) 


    const dropDownMessageList =  (
        <List
            itemLayout="horizontal"
            dataSource={unreadNotificationsList || []}
            bordered={true}
            style={{backgroundColor: "white", minWidth: 500, maxHeight: 500, overflowY: "auto"}}
            renderItem={(item, index) => (
                (() => {
                    switch(item.event) {
                        case("message_created"):
                            return (
                                <NotificationListItem item={item} message={item.event_description} />
                            )
                        case("time_booking_created"):
                            return (
                                <NotificationListItem item={item} message={t('unread-messages.time-booking.info-text')} />
                            )
                        case("ticket_created"):
                            return (
                                <NotificationListItem item={item} message={item.scope_description} />
                            )
                        case("ticket_owner_updated"):
                            return (
                                <NotificationListItem item={item} message={t('unread-messages.ticket-owner-updated.info-text')} />
                            )
                        default:
                            return (
                                <NotificationListItem item={item} message={t('unread-messages.unknown-event')} />
                            );
                    }
                })()
            )}
        />
    )


    return (
        <>
            {contextHolder}
            
           <Dropdown 
                overlay={dropDownMessageList}
                placement="bottomLeft"
                trigger={["click"]}
            >
                <Badge count={unreadNotificationsList.length}>
                    <MailOutlined style={{fontSize: '25px', color: '#FFFFFF', cursor: "pointer"}} />
                </Badge>
            </Dropdown>
            
        </>
    );
}

export default UnreadNotificationList;