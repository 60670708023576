import { useTranslation } from 'react-i18next';
import { Button, Space, Col, Row } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import AssetTypesMenu from "./AssetTypesMenu";
import { getApi } from "../../../utils/RequestBuilder";
import AssetTypeForm from "./AssetTypeForm";
import AddProductLineModal from './AddProductLineModal';
import AddAssetTypeModal from './AddAssetTypeModal';

function AssetTypesOverview() {

    const { t } = useTranslation();

    const [isAddProductLineModalOpen, setIsAddProductLineModalOpen] = useState(0);
    const [isAddAssetTypeModalOpen, setIsAddAssetTypeModalOpen] = useState(0);

    const [assetTypeId, setAssetTypeId] = useState(0)
    const [issues, setIssues] = useState([])
    const [modules, setModules] = useState([])
    const [productLines, setProductLines] = useState([])
    const [productLinesSimple, setProductLinesSimple] = useState([])


    const openPlModal = () => {
        setIsAddProductLineModalOpen(isAddProductLineModalOpen + 1)
    };

    const openAtModal = () => {
        setIsAddAssetTypeModalOpen(isAddAssetTypeModalOpen + 1)
    };

    async function loadIssues(auth) {
        try {
            const result = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/issues");
            let tmpIssues = []
            result.forEach((issue) => {
                let tmpIssue = issue
                tmpIssue.key = issue.id
                tmpIssues.push(tmpIssue)
            })
            setIssues(result)
        } catch (error) {
            console.log(error)
        }
    }

    async function loadModules(auth) {
        try {
            const result = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/modules");
            let tmpModules = []
            result.forEach((module) => {
                let tmpModule = module
                tmpModule.key = module.id
                tmpModules.push(tmpModule)
            })
            setModules(result)
        } catch (error) {
            console.log(error)
        }
    }

    async function loadProductLines(auth) {
        try {
            const result = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/product-lines-extended");
            let tmpProductLines = []
            result.forEach((pl) => {
                let tmpPL = pl
                tmpPL.key = pl.product_line.id
                tmpProductLines.push(tmpPL)
            })
            setProductLines(result)
        } catch (error) {
            console.log(error)
        }
    }

    async function loadProductLinesSimple(auth) {
        try {
            const result = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/product-lines");
            setProductLinesSimple(result)
        } catch (error) {
            console.log(error)
        }
    }

    function handleMenuItemSelect(key) {
        const keys = key.split('-');
        setAssetTypeId(keys[1])
    }

    useEffect(() => {
        loadIssues()
        loadModules()
        loadProductLines()
        loadProductLinesSimple()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Row>
                <Space style={{marginTop: "4px", marginBottom: "12px"}}>
                    <Button onClick={() => openPlModal()}><PlusOutlined />{t('administration.asset-types.form.add.product-line')}</Button>
                    <Button onClick={() => openAtModal()}type="primary"><PlusOutlined />{t('administration.asset-types.form.add.asset-type')}</Button>
                </Space>
            </Row>
            <Row>
                <Col span={6}>
                    <AssetTypesMenu productLines={productLines} callbackFunc={handleMenuItemSelect} callbackforEdit={loadProductLines}  />
                </Col>
                <Col span={18}>
                    <AssetTypeForm id={assetTypeId} modules={modules} issues={issues} callbackFunc={loadProductLines}/>
                </Col>
            </Row>
            <AddProductLineModal isOpen={isAddProductLineModalOpen} callbackFct={loadProductLines} callBackForSelect={loadProductLinesSimple}/>
            <AddAssetTypeModal isOpen={isAddAssetTypeModalOpen} productLineSelection={productLinesSimple}callbackFct={loadProductLines}/>
        </div>
    );
}

export default AssetTypesOverview;
