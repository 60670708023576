import {Avatar, Form, Select} from "antd";
import {getAvatarColor} from "../../../utils/utils";
import {useTranslation} from "react-i18next";

const { Option } = Select;

function CustomerFilter({value, onChange, customers}) {

    const { t } = useTranslation();

    return (
        
        <Select
            value={value}
            showSearch
            allowClear
            width='160'
            placeholder={t('requests.overview.filter.customer')}
            optionFilterProp="children"
            onChange={onChange}
            style={{minWidth: "150px"}}
            filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
        >
            {
                (customers || []).map((customer) => {
                    return(
                        <Option key={customer.id} value={customer.id} searchterm={customer.name}>
                            {customer.name}
                        </Option>);
                })
            }
        </Select>
        
    )
}

export default CustomerFilter