import { Form, Input, Select, Avatar, Badge, Tag, Row, Col, Cascader, Tooltip } from 'antd';
import { CustomerServiceOutlined, CarOutlined, PhoneOutlined, LaptopOutlined, UserOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import { getAvatarColor } from '../../../utils/utils';
import { getPrioTagColor } from '../../../utils/utils';
import {fetchApi} from "../../../utils/RequestBuilder";
//import {buildAssetOptions} from "../../../utils/AssetList";
import {useAuth} from "react-oidc-context";

const { TextArea } = Input;
const { Option } = Select;

function RequestCreateForm({form, auth, customers, manufacturerEngineers}) {
    //Ticket
    const [assets, setAssets]= useState([]);
    const [assetOptions, setAssetOptions] = useState([]);
    const [userOptions, setUsersOptions] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [selectedAsset, setSelectedAsset] = useState();
    const [selectedContact, setSelectedContact] = useState();
    //Service
    const [possibleServices, setPossibleServices]= useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [caseOptions, setCaseOptions] = useState([]);
    const [caseOptionsForService, setCaseOptionsForService] = useState([]);
    


    const { t } = useTranslation();

    const initialStatus = 'IN_PROGRESS';


    const onFinish = (values) => {
        //TODO
    }

    const layout = {
        labelCol: {
            span: 5,
        },
        wrapperCol: {
            span: 19,
        },
    }

    function onCustomerChanged(label, option) {
        setSelectedCustomer(option.value)
        setSelectedAsset(null)
        setSelectedContact(null)
    }

    //Fetch assets from server
    async function loadAssetsForCustomer(customer) {
        if (customer !== undefined) {
            try {
                const jsonData = await fetchApi(auth, "/customers/" + customer + "/assets")
                setAssets(jsonData)
            } catch (error) {
                // Handle error
            }
        }
    }

    const buildAssetOptions = () => {
        let options = []

        assets.forEach((area) => {
            let tmpAreaAssets = []

            area.assets.forEach((asset) => {
                tmpAreaAssets.push({
                    label: asset.name,
                    value: asset.id,
                    position: asset.position
                })
            })

            tmpAreaAssets.sort((a, b) => a.position - b.position);

            if (tmpAreaAssets.length > 0) {
                let tmpAssetOptions = {
                    label: area.name,
                    options: tmpAreaAssets
                }
            options.push(tmpAssetOptions)
            }
        })

        options.sort((a, b) => {
            const positionA = a.options.length > 0 ? a.options[0].position : 0;
            const positionB = b.options.length > 0 ? b.options[0].position : 0;
            return positionA - positionB;
        });

        setAssetOptions(options)
    }

    useEffect(() => {
        buildAssetOptions()
    }, [assets]);

    //Fetch customers from server
    async function loadUsersForCustomer(customer) {
        if (customer !== undefined) {
            try {
                const jsonData = await fetchApi(auth, "/customers/" + customer + "/users")
                setUsersOptions(jsonData)
            } catch (error) {
                // Handle error
            }
        }
    }

    //Fetch Services from server
    async function loadServicesForAsset(customer, asset) {
        if (customer !== undefined) {
            try {
                const jsonData = await fetchApi(auth, "/customers/" + customer + "/assets/" + asset + "/service-availabilities")
                setPossibleServices(jsonData)
            } catch (error) {
                // Handle error
            }
        }
    }

    //Fetch Modules from server
    async function loadModulesForAsset(customer, asset) {
        if (customer !== undefined) {
            try {
                const jsonData = await fetchApi(auth, "/assets/" + asset + "/service-options")
                setCaseOptions(jsonData)
            } catch (error) {
                // Handle error
            }
        }
    }

    const getServiceIcon = (type) => {
        let icon;
        switch (type) {
            case "ONSITE":
                icon = <CarOutlined />;
                break;
            case "REMOTE":
                icon = <LaptopOutlined />;
                break;
            case "GUIDED":
                icon = <CustomerServiceOutlined/>;
                break;
            case "HOTLINE":
                icon = <PhoneOutlined />;
                break;
            case "PERSONAL":
                icon= <UserOutlined />
                break;
            default:
                icon = ""
        } return icon
    }

    const getServiceText = (type) => {
        let text = "";
        switch (type) {
            case "ONSITE":
                text= t('administration.service-types.onsite')
                break;
            case "REMOTE":
                text= t('administration.service-types.remote')
                break;
            case "GUIDED":
                text= t('administration.service-types.guided')
                break;
            case "HOTLINE":
                text= t('administration.service-types.hotline')
                break;
            case "PERSONAL":
                text= t('administration.service-types.personal')
                break;
            default:
                text = ""
        } return text
    }

    const buildServiceOptions = (possibleServices) => {
        let options = []

        for (const [key, value] of Object.entries(possibleServices)) {
            options.push({
                value: key,
                label: (
                    <div style={{ display: "flex", alignItems: "center"}}>
                        {getServiceIcon(key)}{""} 
                        <span style={{marginLeft: "4px"}}>{getServiceText(key)}</span> 
                    </div>
                ),
                disabled: !value
            });    
        }
        return options;
    }

    useEffect(() => {
        setServiceOptions(buildServiceOptions(possibleServices));
    }, [possibleServices]);

   useEffect(() => {
        loadAssetsForCustomer(selectedCustomer)
        loadUsersForCustomer(selectedCustomer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer])

    useEffect(() => {
        if (selectedAsset !== null) {
            loadServicesForAsset(selectedCustomer, selectedAsset);
            loadModulesForAsset(selectedCustomer, selectedAsset);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAsset])

    const handleServiceChange = (value) => {
        //setCaseOptionsForService(caseOptions.value);
        setCaseOptionsForService(getModulesByService(value));
    }


    const getModulesByService = (label) => {
        if (caseOptions[label]) {
            const element = caseOptions[label];
            const updatedElement = element.map(item => {
                const updatedIssues = item.issues.map(issue => ({
                    ...issue,
                    value: issue.id
                }));
                return {
                    ...item,
                    children: updatedIssues,
                    value: item.id
                };
            });
            updatedElement.sort((a, b) => a.label.localeCompare(b.label));
            return updatedElement;
        } else {
            return null; 
        }
    }

      const onValuesChange = (changedValues, allValues) => {
        if ('customer' in changedValues) {
            form.setFieldsValue({
                contact: undefined,
                asset: undefined,
                serviceSelect: undefined,
                caseSelect: undefined
            });
        }
    };

    return (
        <Form
            {...layout}
            autoComplete="off"
            name="basic"
            form={form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            initialValues={{
                ticketStatus: initialStatus
            }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label={t('requests.forms.create.customer')}
                        name="customer"
                        rules={[{
                            required: true,
                            message: t('requests.forms.create.customer') + ' ' + t('requests.forms.create.error-message')
                        }]}
                    >
                        <Select
                            options={customers.map((item) => ({
                                label: item.name,
                                value: item.id,
                            }))}
                            onChange={onCustomerChanged}
                            placeholder={t('requests.forms.create.placeholder.customer')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="asset"
                        label={t('tickets.forms.create.asset')}
                        rules={[{
                            required: true,
                            message: t('tickets.forms.create.asset') + ' ' + t('tickets.forms.create.error-message')
                        }]}
                        
                    >
                        {/*{() => {*/}
                        {/*    return (*/}
                                <Select
                                    options={assetOptions}
                                    onChange={(label, option) => setSelectedAsset(option.value)}
                                    placeholder={t('requests.forms.create.placeholder.asset')}
                                    //value={selectedAsset}
                                />
                            {/*)*/}
                        {/*}}*/}
                    </Form.Item>
                    <Form.Item
                        name="contact"
                        label={t('requests.forms.create.contact')}
                        rules={[{
                            required: true,
                            message: t('requests.forms.create.contact') + ' ' + t('requests.forms.create.error-message')
                        }]}
                        shouldUpdate
                    >
                        {/* {() => {
                            return ( */}
                                <Select
                                    showSearch
                                    filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                                    optionFilterProp="children"
                                    onChange={(value) => setSelectedContact(value)}
                                    //value={selectedContact}
                                    key={selectedContact}
                                    placeholder={t('requests.forms.create.placeholder.contact')}
                                    
                                >
                                    {
                                        (userOptions || []).map((user) => {
                                            return (
                                                <Option key={user.id} value={user.id} searchterm={user.name}>
                                                    <Avatar style={{backgroundColor: getAvatarColor(user.signature)}}>
                                                        {user.signature}
                                                    </Avatar> {user.name}
                                                </Option>);
                                        })
                                    }
                                </Select>
                            {/* )
                        }} */}
                    </Form.Item>
                    <Form.Item
                        label={t('requests.forms.create.name')}
                        name="title"
                        rules={[{
                            required: true,
                            message: t('tickets.forms.create.name') + ' ' + t('tickets.forms.create.error-message')
                        }]}  
                    >
                        <Input 
                            showCount 
                            maxLength={48}
                            placeholder={t('requests.forms.create.placeholder.ticket-name')}/>
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.forms.create.description')}
                        name="description"
                        rules={[{ 
                            required: true,
                            message: t('tickets.forms.create.description') + ' ' + t('tickets.forms.create.error-message')
                        }]}
                    >
                        <TextArea 
                            rows={4}
                            placeholder={t('requests.forms.create.placeholder.description')} />
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.forms.create.priority')}
                        name="priority"
                        rules={[{
                            required: true,
                            message: t('tickets.forms.create.priority') + ' ' + t('tickets.forms.create.error-message')
                        }]}
                    >
                        <Select
                            style={{
                                
                            }}
                            placeholder={t('requests.forms.create.placeholder.priority')}
                            options={[
                                {
                                    value: 'MINOR',
                                    label: <Tag color={getPrioTagColor('MINOR')}>MINOR</Tag>
                                },
                                {
                                    value: 'MAJOR',
                                    label: <Tag color={getPrioTagColor('MAJOR')}>MAJOR</Tag>
                                },
                                {
                                    value: 'CRITICAL',
                                    label: <Tag color={getPrioTagColor('CRITICAL')}>CRITICAL</Tag>
                                }
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('requests.forms.create.ticket-status')}
                        name="ticketStatus"
                        rules={[{ required: true}]}
                    >
                        <Tooltip title={t('requests.forms.create.tooltip')}>
                            <Select
                                style={{
                                
                                }}
                                disabled
                                value={initialStatus}
                                options={[
                                    {
                                        value: 'OPEN',
                                        label: <Badge status="warning" text="Open" />
                                    },
                                    {
                                        value: 'IN_PROGRESS',
                                        label: <Badge color="#00B5E2"  text="In Progress" />
                                    },
                                    {
                                        value: 'CLOSED',
                                        label: <Badge status="success" text="Closed" />
                                    }
                                ]}
                            >
                            </Select>
                        </Tooltip>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label={t('tickets.details.services-area.forms.create.labels.type')}
                    name="serviceSelect"
                    rules={[{
                        required: true,
                        message: t('tickets.details.services-area.forms.create.labels.type') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                    }]}
                    >
                        <Select
                            options={serviceOptions}
                            placeholder={t('tickets.details.services-area.forms.create.placeholder.type')}
                            onChange={handleServiceChange}
                            //size={"large"}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.create.labels.case')}
                        name="caseSelect"
                        /* rules={[{
                            required: true,
                            message: t('tickets.details.services-area.forms.create.labels.case') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                        }]} */
                    >
                        <Cascader 
                            options={caseOptionsForService} 
                            placeholder={t('tickets.details.services-area.forms.create.placeholder.case')}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.edit.labels.manufacturer-engineer')}
                        name="manufacturerEngineerSelect"
                        //hidden="true"
                        rules={[{
                            required: true,
                            message: t('tickets.details.services-area.forms.edit.labels.manufacturer-engineer') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                        }]}
                    >
                        <Select
                            //value={selectedEngineer}
                            placeholder={t('requests.forms.create.placeholder.service-engineer')}   
                        >
                            { 
                                (manufacturerEngineers || []).map((engineer) => {
                                    return(
                                        <Option key={engineer.id} value={engineer.id} searchterm={engineer.name}>
                                            <Avatar style={{ backgroundColor: getAvatarColor(engineer.signature)}}>
                                                {engineer.signature}
                                            </Avatar>  {engineer.name}
                                        </Option>);
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default RequestCreateForm;
